import "../style.scss";
import React, { useState, useEffect } from "react";
import Footer_Under from "components/Footer_Under";
import Header_Under from "components/Header_Under";
import { Parallax } from "react-parallax";
import con from "../img/Anlagegrafik.webp";
import NoSSR from "react-no-ssr";
import { HashLink } from "react-router-hash-link";
import { t } from "i18next";

export default function Page() {
  useEffect(() => {
    //mobile nav
    const openIcon = document.querySelector(".fa-bars");
    const closeIcon = document.querySelector(".close-nav");
    const mobileBG = document.querySelector(".mobile-bg");
    const mobileNav = document.querySelector(".mobile-nav");
    const navItem = document.querySelector(".nav-item");

    openIcon.addEventListener("click", () => {
      mobileBG.classList.toggle("display-none");
      mobileNav.classList.remove("slide-out");
      mobileNav.classList.toggle("slide-in");
      openIcon.classList.toggle("display-none");
    });

    closeIcon.addEventListener("click", () => {
      setTimeout(() => {
        mobileBG.classList.toggle("display-none");
        openIcon.classList.toggle("display-none");
      }, 500);
      mobileNav.classList.toggle("slide-in");
      mobileNav.classList.toggle("slide-out");
    });

    navItem.addEventListener("click", () => {
      mobileBG.classList.toggle("display-none");
      mobileNav.classList.remove("slide-out");
      mobileNav.classList.toggle("slide-in");
      openIcon.classList.toggle("display-none");
    });
  });

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  return (
    <html
      style={{ backgroundColor: "#000000", minHeight: windowDimensions.height }}
      lang="de"
    >
      <body style={{ backgroundColor: "#191919" }}>
        {/* Nav */}
        <Header_Under></Header_Under>

        <div style={{ height: 180 }}></div>

        <Parallax
          blur={{ min: -15, max: 15 }}
          // blur={0}
          bgImage={con}
          bgImageAlt=" "
          strength={350}
          renderLayer={(percentage) => (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: 350,
                background: `rgba(0, 0, 0, ${0.3})`,
              }}
            />
          )}
        >
          <div
            style={{
              height: 350,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <p
              style={{
                fontSize: 55,
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              {"Operative Unterstützung"}
            </p>
          </div>
        </Parallax>

        <div
          className="container"
          style={{ padding: "50px 50px", paddingBottom: "50px" }}
        >
          <h2
            style={{
              color: "#a4ffee",
              textAlign: "left",
              paddingBottom: "20px",
            }}
          >
            {t("create")}
          </h2>
          <h3
            style={{
              color: "#ffffff",
              textAlign: "left",
              paddingBottom: "0px",
            }}
          ></h3>
          <p style={{ color: "#fff", whiteSpace: "pre-wrap" }}>
            {t("creation") + " " + t("fun") + " "}
          </p>
          <br></br>
          <p style={{ color: "#fff", whiteSpace: "pre-wrap" }}>
            {t("contactus")}
          </p>

          <div style={{ paddingBottom: "30px" }}></div>
          <NoSSR>
            {/* <ScrollToTop /> */}
            <div className="buttons" style={{ paddingBottom: 50 }}>
              <HashLink to="/#kontakt" className="btn btn-light btn-register">
                {t("contact")}
              </HashLink>
            </div>
          </NoSSR>
          <div style={{ paddingBottom: "0px" }}></div>
        </div>

        <Footer_Under></Footer_Under>
      </body>
    </html>
  );
}
