import React from "react";
import ReactDOM from "react-dom/client";
import "./style.scss";
import App from "./App";
import Impressum from "./pages/Impressum";
import Shop from "./pages/Shop";
import Main from "./pages/Main";
import NoSSR from "react-no-ssr";

import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  Routes,
  Route,
  createBrowserRouter,
} from "react-router-dom";
import Datenschutz from "pages/Datenschutz";
import Consulting from "pages/Consulting";
import Analytics from "pages/Analytics";
import Operatives from "pages/Operatives";
import Business from "pages/Business";
import Market from "pages/Market";
//https://github.com/remix-run/react-router/blob/main/docs/start/tutorial.md
import "./i18n.js"

 
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  // <BrowserRouter>
  //   <App />
  // </BrowserRouter>
  // </React.StrictMode>
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/consulting" element={<Consulting />} />
      <Route path="/analytics" element={<Analytics />} />
      <Route path="/operatives" element={<Operatives />} />
      <Route path="/business" element={<Business />} />
      <Route path="/market" element={<Market />} />
      <Route path="/impressum" element={<Impressum />} />
      <Route path="/shop" element={<Shop />} />
      <Route path="/datenschutz" element={<Datenschutz />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
