import "../style.scss";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import logo from "../img/logo.png";
import Header from "components/Header";
import Footer_Under from "components/Footer_Under";
import Header_Under from "components/Header_Under";

export default function Shop() {
  useEffect(() => {
    //mobile nav
    const openIcon = document.querySelector(".fa-bars");
    const closeIcon = document.querySelector(".close-nav");
    const mobileBG = document.querySelector(".mobile-bg");
    const mobileNav = document.querySelector(".mobile-nav");
    const navItem = document.querySelector(".nav-item");

    openIcon.addEventListener("click", () => {
      mobileBG.classList.toggle("display-none");
      mobileNav.classList.remove("slide-out");
      mobileNav.classList.toggle("slide-in");
      openIcon.classList.toggle("display-none");
    });

    closeIcon.addEventListener("click", () => {
      setTimeout(() => {
        mobileBG.classList.toggle("display-none");
        openIcon.classList.toggle("display-none");
      }, 500);
      mobileNav.classList.toggle("slide-in");
      mobileNav.classList.toggle("slide-out");
    });

    navItem.addEventListener("click", () => {
      mobileBG.classList.toggle("display-none");
      mobileNav.classList.remove("slide-out");
      mobileNav.classList.toggle("slide-in");
      openIcon.classList.toggle("display-none");
    });
  });

  return (
    <html lang="de">
      <body style={{ backgroundColor: "#191919" }}>
        {/* Mobile Nav */}
        {/* <div className="mobile-bg display-none">
          <ul className="mobile-nav">
            <li>
              <a href="/" className="nav-item">
                Zurück
              </a>
            </li>
          </ul>
          <div
            className="close-nav"
            style={{
              backgroundColor: "transparent",
              width: "100%",
              height: "100%",
            }}
          ></div>
        </div> */}
        {/* Nav */}
        <Header_Under></Header_Under>

        <div style={{ height: 130 }}></div>

        {/* <header>
          <nav className="container">
            <a>
              <img src={logo} alt="" />
            </a>

            <i
              style={{ paddingRight: "20px", color: "#a4ffee" }}
              className="fas fa-bars"
            >
              <FontAwesomeIcon
                style={{ color: "#a4ffee", cursor: "pointer" }}
                icon={icon({ name: "bars", style: "solid" })}
              />
            </i>
            <ul>
              <li>
                <a href="/">Zurück</a>
              </li>
            </ul>
          </nav>
        </header> */}

        <div
          className="container"
          style={{ padding: "100px 50px", paddingBottom: "50px" }}
        >
          <h2
            style={{
              color: "#a4ffee",
              textAlign: "left",
              paddingBottom: "20px",
            }}
          >
            Marketplace
          </h2>
          <p style={{ color: "#fff", whiteSpace: "pre-wrap" }}>
            {`Coming soon.`}
          </p>
        </div>

        <Footer_Under></Footer_Under>

        {/* <footer>
          <div className="container">
            <ul className="flex-row">
              <li>
                <a href="/">Zurück</a>
              </li>
            </ul>
            <ul className="flex-row">
              <li>
                <i className="fa-brands fa-google-play">
                  <FontAwesomeIcon
                    icon={icon({ name: "google-play", style: "brands" })}
                  />
                </i>
              </li>
              <li>
                <i className="fa-brands fa-app-store-ios">
                  <FontAwesomeIcon
                    icon={icon({ name: "app-store-ios", style: "brands" })}
                  />
                </i>
              </li>
            </ul>
            <p>{"© 2022 Innovation2Go"}</p>
          </div>
        </footer> */}
      </body>
    </html>
  );
}
