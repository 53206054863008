import React, { useEffect, useRef, useState } from "react";
import "../style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  getAuth,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import car1 from "../img/car-1.jpg";
import car2 from "../img/car-2.jpg";
import car3 from "../img/car-3.jpg";
import car4 from "../img/car-4.jpg";
import car5 from "../img/car-5.jpg";
import hands from "../img/hands-wide.png";
import people from "../img/people.png";
import leftimg from "../img/left_table.webp";
import rightimg from "../img/right_table.webp";
import leftimg2 from "../img/Anlagegrafik.webp";
import rightimg2 from "../img/rightimg.jpg";
import con1 from "../img/consult1.jpg";
import con2 from "../img/consult2.jpg";
import con3 from "../img/Anlagegrafik.webp";
import con4 from "../img/consult3.webp";
import con5 from "../img/consult5.png";
import feedback from "../img/feedback.webp";
import emailjs from "@emailjs/browser";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Background, Parallax } from "react-parallax";
import NoSSR from "react-no-ssr";
import ScrollToTop from "components/ScrollToTop";
import { Link } from "react-router-dom";
import { t } from "i18next";

function Home() {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // const login3 = document.querySelector(".btn-login3");
    // const forgot = document.querySelector(".btn-login-forgot");
    // const loginClose = document.querySelector(".modal-login-close");
    // const loginModal = document.querySelector(".login-modal-wrap");
    // login3.addEventListener("click", () => {
    //   loginModal.classList.toggle("display-none");
    // });
    // loginClose.addEventListener("click", () => {
    //   loginModal.classList.toggle("display-none");
    // });
    //login
    // const email = document.querySelector(".login-mail");
    // const password = document.querySelector(".login-password");
    // forgot.addEventListener("click", () => {
    //   const auth = getAuth();
    //   sendPasswordResetEmail(auth, email.value)
    //     .then(() => {
    //       alert("E-Mail zur Zurücksetzung versendet.");
    //       // Password reset email sent!
    //       // ..
    //     })
    //     .catch((error) => {
    //       const errorCode = error.code;
    //       const errorMessage = error.message;
    //       alert(errorMessage);
    //       // ..
    //     });
    // });
    // const loginEmailPassword = async () => {
    //   const loginEmail = email.value;
    //   const loginPassword = password.value;
    //   const auth = getAuth();
    //   signInWithEmailAndPassword(auth, loginEmail, loginPassword)
    //     .then((userCredential) => {
    //       const user = userCredential.user;
    //       window.location.replace("home/plans");
    //     })
    //     .catch((error) => {
    //       const errorCode = error.code;
    //       const errorMessage = error.message;
    //       alert(errorMessage);
    //     });
    // };
    // const loginFirebase = document.querySelector(".modal-login");
    // if (loginFirebase) {
    //   loginFirebase.addEventListener("click", loginEmailPassword);
    // }
  }, []);

  const handleDragStart = (e) => e.preventDefault();

  // const itemsLength = Array.from({ length: 5 });

  // const items = itemsLength.map((item, index) => {
  //   const style = { height: 200 + index * 10 };
  //   return <div className="item" style={style} data-value={index + 1} />;
  // });

  const items = [
    <img
      src={car1}
      style={{ height: 300, width: 450 }}
      onDragStart={handleDragStart}
      role="presentation"
    />,
    <img
      src={car2}
      style={{ height: 300, width: 450 }}
      onDragStart={handleDragStart}
      role="presentation"
    />,
    <img
      src={car3}
      style={{ height: 300, width: 450 }}
      onDragStart={handleDragStart}
      role="presentation"
    />,
    <img
      src={car4}
      style={{ height: 300, width: 450 }}
      onDragStart={handleDragStart}
      role="presentation"
    />,
    <img
      src={car5}
      style={{ height: 300, width: 450 }}
      onDragStart={handleDragStart}
      role="presentation"
    />,
    // <img
    //   src={car1}
    //   style={{ height: 300, width: 450 }}
    //   onDragStart={handleDragStart}
    //   role="presentation"
    // />,
    // <img
    //   src={car2}
    //   style={{ height: 300, width: 450 }}
    //   onDragStart={handleDragStart}
    //   role="presentation"
    // />,
    // <img
    //   src={car3}
    //   style={{ height: 300, width: 450 }}
    //   onDragStart={handleDragStart}
    //   role="presentation"
    // />,
    // <img
    //   src={car4}
    //   style={{ height: 300, width: 450 }}
    //   onDragStart={handleDragStart}
    //   role="presentation"
    // />,
  ];

  // const responsive = {
  //   0: { items: 1 },
  //   450: { items: 2 },
  //   900: { items: 3 },
  //   1350: { items: 4 },
  // };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_it1p92a",
        "template_gby594t",
        form.current,
        "mjtfPcZtt4XWpDNif"
      )
      .then(
        (result) => {
          alert("Anfrage versendet.");
          console.log(result.text);
        },
        (error) => {
          alert("Fehler.");
          console.log(error.text);
        }
      );
  };

  return (
    <main style={{ backgroundColor: "#191919", paddingTop: 200 }}>
      <AliceCarousel
        mouseTracking
        items={items}
        disableDotsControls
        disableButtonsControls
        autoPlay={true}
        autoPlayInterval={3000}
        animationDuration={1000}
        infinite={true}
        autoWidth={true}
        autoHeight={false}
        // responsive={responsive}
      />

      <div style={{ textAlign: "center", paddingTop: 30, paddingBottom: 70 }}>
        <a
          style={{
            color: "#a4ffee",
            fontSize:
              windowDimensions.width * 0.09 < 65
                ? windowDimensions.width * 0.09
                : 65,
            fontWeight: "bolder",
          }}
        >
          {"Innovation2Go"}
          <sup style={{ fontSize: 25 }}>©</sup>
          {t("welc")}
          <div style={{ height: 5 }}></div>
          {t("you")}
          <div style={{ height: 5 }}></div>
          {t("glad")}

          <div style={{ height: 20 }}></div>
          <div style={{ fontSize: 20, fontWeight: "normal", color: "#fff" }}>
            {t("tailor")}
          </div>
          <div style={{ height: 30 }}></div>

          {/* {windowDimensions.width * 0.02 < 15 ? ( */}
          <div
            style={{
              fontSize: 15,
              lineHeight: 1.7,
              fontWeight: "normal",
              color: "#fff",
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <div>
              {t("small")}
              <a style={{ textDecorationLine: "underline", color: "#a4ffee" }}>
                {t("strat")}
              </a>
              {t("andthe")}
            </div>
            <div>
              <a style={{ textDecorationLine: "underline", color: "#a4ffee" }}>
                {t("dev")}
              </a>
              <a>{t("and")}</a>
              <a style={{ textDecorationLine: "underline", color: "#a4ffee" }}>
                {t("innov")}
              </a>
            </div>
            <div>{t("reach")}</div>
            <a>{t("inivgoals")}</a>
            <div>{t("shared")}</div>
          </div>
        </a>
      </div>

      <Parallax
        blur={{ min: -15, max: 15 }}
        // blur={0}
        bgImage={hands}
        bgImageAlt=" "
        strength={350}
        renderLayer={(percentage) => (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: 350,
              background: `rgba(0, 0, 0, ${0.3})`,
            }}
          />
        )}
      >
        <div
          style={{
            height: 350,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <p
            style={{
              fontSize: 40,
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            {t("aboutus")}
          </p>
        </div>
      </Parallax>

      <div
        style={{
          textAlign: "center",
          paddingTop: 30,
          paddingBottom: 70,
          width: "100%",
        }}
      >
        <p style={{ fontSize: 20, color: "#a4ffee" }}>{t("solutions")}</p>

        <div style={{ height: 30 }}></div>

        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <p
            style={{
              maxWidth: 750,
              color: "#fff",
              fontSize: 15,
              textAlign: "center",
              lineHeight: 1.7,
              fontWeight: "normal",
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            {t("wefrom") + " "}
            <a style={{ textDecorationLine: "underline", color: "#a4ffee" }}>
              {"INNOVATION2GO"}
            </a>
            {" " + t("step")}
            <br />
            <br />
            {" " + t("ourteam")}
          </p>
        </div>
      </div>

      <Parallax
        // blur={{ min: -15, max: 15 }}
        blur={0}
        bgImage={people}
        bgImageAlt=" "
        strength={350}
        renderLayer={(percentage) => (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: 350,
              background: `rgba(0, 0, 0, ${0.3})`,
            }}
          />
        )}
      >
        <div
          id="portfolio"
          style={{
            height: 350,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <p
            style={{
              fontSize: 40,
              color: "#fff",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {t("portfolio")}
            <p
              style={{
                fontSize: 20,
                color: "#fff",
                fontWeight: "normal",
                textAlign: "center",
                paddingTop: 20,
              }}
            >
              {t("success")}
            </p>
          </p>
        </div>
      </Parallax>

      <div
        style={{
          textAlign: "center",
          paddingTop: 50,
          // paddingBottom: 70,
          width: "100%",
        }}
      >
        {windowDimensions.width < 900 ? (
          <div>
            <table
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                padding: 20,
              }}
            >
              <tr>
                <th
                  style={{
                    textAlign: "start",
                    color: "#a4ffee",
                    fontSize: 25,
                    paddingTop: 30,
                    paddingBottom: 40,
                  }}
                >
                  {t("consulting")}
                </th>
              </tr>
              <tr>
                <td>
                  <img
                    src={leftimg}
                    style={{
                      width: windowDimensions.width - 70,
                      maxWidth: 450,
                      marginRight: 20,
                    }}
                    onDragStart={handleDragStart}
                    role="presentation"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    paddingTop: 50,
                    paddingBottom: 20,
                    textAlign: "start",
                    color: "#a4ffee",
                    fontSize: 20,
                  }}
                >
                  {t("pioneering")}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    paddingTop: 20,
                    paddingBottom: 30,
                    textAlign: "start",
                    color: "#fff",
                    fontSize: 15,
                    maxWidth: 450,
                    paddingRight: 20,
                    lineHeight: 1.7,
                    fontWeight: "normal",
                    verticalAlign: "top",
                  }}
                >
                  Einfach, schnell und unbürokratisch. Wir wissen genau wie
                  wichtig es, vor allem in der frühen Startphase ist, schnell
                  Lösungen zu finden und diese voranzutreiben. Aus diesem Grund
                  arbeiten wir vor allem mit Start-Ups sehr agil zusammen und
                  sind immer bemüht, schnell und pragmatisch Lösungen
                  anzubieten. Daher sind nahezu alle Lösungen individuell und
                  zielgerichtet für dein Unternehmen.
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    paddingTop: 30,
                    paddingBottom: 40,
                    textAlign: "start",
                    color: "#a4ffee",
                    fontSize: 25,
                  }}
                >
                  {t("management")}
                </td>
              </tr>

              <tr>
                <td>
                  <img
                    src={rightimg}
                    style={{
                      width: windowDimensions.width - 70,
                      maxWidth: 450,
                      marginRight: 20,
                    }}
                    onDragStart={handleDragStart}
                    role="presentation"
                  />
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    paddingTop: 50,
                    paddingBottom: 20,
                    textAlign: "start",
                    color: "#a4ffee",
                    fontSize: 20,
                  }}
                >
                  {t("new")}
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    paddingTop: 20,
                    paddingBottom: 30,
                    textAlign: "start",
                    color: "#fff",
                    fontSize: 15,
                    maxWidth: 450,
                    paddingRight: 20,
                    lineHeight: 1.7,
                    fontWeight: "normal",
                  }}
                >
                  {t("support")}
                  <br />
                  <br />
                  <li> {t("agility")}</li>
                  <li> {t("sus")}</li>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    paddingTop: 30,
                    paddingBottom: 40,
                    textAlign: "start",
                    color: "#a4ffee",
                    fontSize: 25,
                  }}
                >
                  {t("numbers")}
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    src={leftimg2}
                    style={{
                      width: windowDimensions.width - 70,
                      maxWidth: 450,
                      marginRight: 20,
                    }}
                    onDragStart={handleDragStart}
                    role="presentation"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    paddingTop: 50,
                    paddingBottom: 20,
                    textAlign: "start",
                    color: "#a4ffee",
                    fontSize: 20,
                  }}
                >
                  {t("future")}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    paddingTop: 20,
                    paddingBottom: 30,
                    textAlign: "start",
                    color: "#fff",
                    fontSize: 15,
                    maxWidth: 450,
                    paddingRight: 20,
                    lineHeight: 1.7,
                    fontWeight: "normal",
                    verticalAlign: "top",
                  }}
                >
                  {t("know")}
                  <br />
                  <br />
                  {t("kpi")}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    paddingTop: 30,
                    paddingBottom: 40,
                    textAlign: "start",
                    color: "#a4ffee",
                    fontSize: 25,
                  }}
                >
                  {t("test")}
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    src={rightimg2}
                    style={{
                      width: windowDimensions.width - 70,
                      maxWidth: 450,
                      marginRight: 20,
                    }}
                    onDragStart={handleDragStart}
                    role="presentation"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    paddingTop: 50,
                    paddingBottom: 20,
                    textAlign: "start",
                    color: "#a4ffee",
                    fontSize: 20,
                  }}
                >
                  {t("reality")}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    paddingTop: 20,
                    paddingBottom: 30,
                    textAlign: "start",
                    color: "#fff",
                    fontSize: 15,
                    maxWidth: 450,
                    paddingRight: 20,
                    lineHeight: 1.7,
                    fontWeight: "normal",
                  }}
                >
                  <p style={{ textDecorationLine: "underline" }}>
                    {"Market Test:"}
                  </p>
                  {t("yourprod")}
                  <br />
                  <br />
                  <p style={{ textDecorationLine: "underline" }}>
                    {"Business Pitch:"}
                  </p>
                  {t("here")}
                </td>
              </tr>
            </table>
          </div>
        ) : (
          <table
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              padding: 20,
              // backgroundColor: "#fff",
            }}
          >
            <tr>
              <th
                style={{
                  textAlign: "start",
                  color: "#a4ffee",
                  fontSize: 25,
                  paddingTop: 30,
                  paddingBottom: 40,
                }}
              >
                {t("consulting")}
              </th>
              <th
                style={{
                  paddingTop: 30,
                  paddingBottom: 40,
                  textAlign: "start",
                  color: "#a4ffee",
                  fontSize: 25,
                }}
              >
                {t("management")}
              </th>
            </tr>
            <tr>
              <td>
                <img
                  src={leftimg}
                  style={{
                    width: windowDimensions.width / 2 - 30,
                    maxWidth: 450,
                    marginRight: 20,
                  }}
                  onDragStart={handleDragStart}
                  role="presentation"
                />
              </td>
              <td>
                <img
                  src={rightimg}
                  style={{
                    width: windowDimensions.width / 2 - 30,
                    maxWidth: 450,
                  }}
                  onDragStart={handleDragStart}
                  role="presentation"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  paddingTop: 50,
                  paddingBottom: 20,
                  textAlign: "start",
                  color: "#a4ffee",
                  fontSize: 20,
                }}
              >
                {t("pioneering")}
              </td>
              <td
                style={{
                  paddingTop: 50,
                  paddingBottom: 20,
                  textAlign: "start",
                  color: "#a4ffee",
                  fontSize: 20,
                }}
              >
                {t("new")}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  paddingTop: 20,
                  paddingBottom: 30,
                  textAlign: "start",
                  color: "#fff",
                  fontSize: 15,
                  maxWidth: 450,
                  paddingRight: 20,
                  lineHeight: 1.7,
                  fontWeight: "normal",
                  verticalAlign: "top",
                }}
              >
                {t("fast")}
              </td>
              <td
                style={{
                  paddingTop: 20,
                  paddingBottom: 30,
                  textAlign: "start",
                  color: "#fff",
                  fontSize: 15,
                  maxWidth: 450,
                  paddingRight: 20,
                  lineHeight: 1.7,
                  fontWeight: "normal",
                }}
              >
                {t("support")}
                <br />
                <br />
                <li> {t("agility")}</li>
                <li> {t("sus")}</li>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  paddingTop: 30,
                  paddingBottom: 40,
                  textAlign: "start",
                  color: "#a4ffee",
                  fontSize: 25,
                }}
              >
                {t("numbers")}
              </td>
              <td
                style={{
                  paddingTop: 30,
                  paddingBottom: 40,
                  textAlign: "start",
                  color: "#a4ffee",
                  fontSize: 25,
                }}
              >
                {t("test")}
              </td>
            </tr>
            <tr>
              <td>
                <img
                  src={leftimg2}
                  style={{
                    width: windowDimensions.width / 2 - 30,
                    maxWidth: 450,
                    marginRight: 20,
                  }}
                  onDragStart={handleDragStart}
                  role="presentation"
                />
              </td>
              <td>
                <img
                  src={rightimg2}
                  style={{
                    width: windowDimensions.width / 2 - 30,
                    maxWidth: 450,
                  }}
                  onDragStart={handleDragStart}
                  role="presentation"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  paddingTop: 50,
                  paddingBottom: 20,
                  textAlign: "start",
                  color: "#a4ffee",
                  fontSize: 20,
                }}
              >
                {t("future")}
              </td>
              <td
                style={{
                  paddingTop: 50,
                  paddingBottom: 20,
                  textAlign: "start",
                  color: "#a4ffee",
                  fontSize: 20,
                }}
              >
                {t("reality")}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  paddingTop: 20,
                  paddingBottom: 30,
                  textAlign: "start",
                  color: "#fff",
                  fontSize: 15,
                  maxWidth: 450,
                  paddingRight: 20,
                  lineHeight: 1.7,
                  fontWeight: "normal",
                  verticalAlign: "top",
                }}
              >
                {t("know")}
                <br />
                <br />
                {t("kpi")}
              </td>
              <td
                style={{
                  paddingTop: 20,
                  paddingBottom: 30,
                  textAlign: "start",
                  color: "#fff",
                  fontSize: 15,
                  maxWidth: 450,
                  paddingRight: 20,
                  lineHeight: 1.7,
                  fontWeight: "normal",
                }}
              >
                <p style={{ textDecorationLine: "underline" }}>
                  {"Market Test:"}
                </p>
                {t("yourprod")}
                <br />
                <br />
                <p style={{ textDecorationLine: "underline" }}>
                  {"Business Pitch:"}
                </p>
                {t("here")}
              </td>
            </tr>
          </table>
        )}
        <p
          style={{
            padding: 20,
            textAlign: "center",
            color: "#fff",
            fontSize: 15,
            paddingRight: 20,
            lineHeight: 1.7,
            fontWeight: "normal",
          }}
        >
          {t("contactus")}
        </p>
        <a href="#support" className="btn btn-light btn-register">
          {t("contact")}
        </a>
        <div
          id="service"
          style={{
            backgroundColor: "#000",
            width: "100%",
            paddingTop: 70,
            marginTop: 50,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 0,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div>
            <p style={{ fontSize: 40, color: "#a4ffee" }}>{"Services"}</p>

            <div
              style={{
                color: "#fff",
                marginTop: 20,
                paddingBottom: 30,
                fontSize: 15,
                maxWidth: 900,
                // paddingRight: 20,
                lineHeight: 1.7,
                fontWeight: "normal",
              }}
            >
              {t("inno")}
              <br />
              <a
                style={{
                  color: "#a4ffee",
                  fontSize: 17,
                }}
              >
                {"I N N O V A T I O N 2 G O"}
              </a>
              <br />
              {t("canhelp")}
            </div>
          </div>
        </div>
      </div>

      <section style={{ backgroundColor: "#000" }} className="about about-2">
        <div style={{ gap: 20 }} className="container flex-row">
          <div
            style={{
              margin: 0,
              padding: 0,
              gap: 0,
            }}
            className="right"
          >
            <Parallax
              blur={0}
              bgImage={con1}
              bgImageAlt=" "
              strength={650}
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "#fff",
              }}
              renderLayer={(percentage) => (
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    background: `rgba(0, 0, 0, ${0.0})`,
                  }}
                />
              )}
            >
              <div
                style={{
                  height: 350,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              ></div>
            </Parallax>
          </div>

          <div
            style={{
              color: "#fff",
              textAlign: "center",
              backgroundColor: "#191919",
              margin: 0,
              padding: 0,
              gap: 0,
            }}
            className="right"
          >
            <div style={{ padding: 20 }}>
              <h3 style={{ color: "#fff", paddingBottom: 10 }}>Consulting</h3>
              <h4 style={{ color: "#fff" }}>{t("goals")}</h4>
              <p>{t("knowhow")}</p>
              <p>{t("prompt")}</p>
              <p>{t("wind")}</p>
              <NoSSR>
                <ScrollToTop />
                <div className="buttons" style={{ paddingBottom: 50 }}>
                  <Link to="/consulting" className="btn btn-light btn-register">
                    {t("more")}
                  </Link>
                </div>
              </NoSSR>
            </div>
          </div>
        </div>
        <div style={{ gap: 20, marginTop: 20 }} className="container flex-row">
          <div
            style={{
              margin: 0,
              padding: 0,
              gap: 0,
            }}
            className="right"
          >
            <Parallax
              blur={0}
              bgImage={con2}
              bgImageAlt=" "
              strength={650}
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "#fff",
              }}
              renderLayer={(percentage) => (
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    background: `rgba(0, 0, 0, ${0.0})`,
                  }}
                />
              )}
            >
              <div
                style={{
                  height: 350,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              ></div>
            </Parallax>
          </div>

          <div
            style={{
              color: "#fff",
              textAlign: "center",
              backgroundColor: "#227374",
              margin: 0,
              padding: 0,
              gap: 0,
            }}
            className="left"
          >
            <div style={{ padding: 20 }}>
              <h3 style={{ color: "#fff" }}>Analytics</h3>
              <h4 style={{ color: "#fff" }}>{t("transp")}</h4>
              <p>{t("invest")}</p>
              <NoSSR>
                <ScrollToTop />
                <div className="buttons" style={{ paddingBottom: 50 }}>
                  <Link to="/analytics" className="btn btn-light btn-register">
                    {t("more")}
                  </Link>
                </div>
              </NoSSR>
            </div>
          </div>
        </div>
        <div style={{ gap: 20, marginTop: 20 }} className="container flex-row">
          <div
            style={{
              margin: 0,
              padding: 0,
              gap: 0,
            }}
            className="right"
          >
            <Parallax
              blur={0}
              bgImage={con3}
              bgImageAlt=" "
              strength={650}
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "#fff",
              }}
              renderLayer={(percentage) => (
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    background: `rgba(0, 0, 0, ${0.0})`,
                  }}
                />
              )}
            >
              <div
                style={{
                  height: 350,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              ></div>
            </Parallax>
          </div>

          <div
            style={{
              color: "#000",
              textAlign: "center",
              backgroundColor: "#a4ffee",
              margin: 0,
              padding: 0,
              gap: 0,
            }}
            className="right"
          >
            <div style={{ padding: 20 }}>
              <h3 style={{ color: "#000" }}>Operatives</h3>
              <p>{t("create")}</p>
              <p>{t("creation")}</p>
              <p>{t("fun")}</p>
              <NoSSR>
                <ScrollToTop />
                <div className="buttons" style={{ paddingBottom: 50 }}>
                  <Link to="/operatives" className="btn btn-gray btn-regidster">
                    {t("more")}
                  </Link>
                </div>
              </NoSSR>
            </div>
          </div>
        </div>
        <div style={{ gap: 20, marginTop: 20 }} className="container flex-row">
          <div
            style={{
              margin: 0,
              padding: 0,
              gap: 0,
            }}
            className="right"
          >
            <Parallax
              blur={0}
              bgImage={con4}
              bgImageAlt=" "
              strength={650}
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "#fff",
              }}
              renderLayer={(percentage) => (
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    background: `rgba(0, 0, 0, ${0.0})`,
                  }}
                />
              )}
            >
              <div
                style={{
                  height: 350,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              ></div>
            </Parallax>
          </div>

          <div
            style={{
              color: "#000",
              textAlign: "center",
              backgroundColor: "#48c6c7",
              margin: 0,
              padding: 0,
              gap: 0,
            }}
            className="left"
          >
            <div style={{ padding: 20 }}>
              <h3 style={{ color: "#000" }}>{t("space")}</h3>
              <h4 style={{ color: "#000" }}>{t("products")}</h4>
              <p>{t("strong")}</p>
              <p>{t("personal")}</p>
              <p>{t("free")}</p>
              <NoSSR>
                <ScrollToTop />
                <div className="buttons" style={{ paddingBottom: 50 }}>
                  <Link to="/business" className="btn btn-gray btn-regidster">
                    {t("more")}
                  </Link>
                </div>
              </NoSSR>
            </div>
          </div>
        </div>
        <div style={{ gap: 20, marginTop: 20 }} className="container flex-row">
          <div
            style={{
              margin: 0,
              padding: 0,
              gap: 0,
            }}
            className="right"
          >
            <Parallax
              blur={0}
              bgImage={con5}
              bgImageAlt=" "
              strength={650}
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "#fff",
              }}
              renderLayer={(percentage) => (
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    background: `rgba(0, 0, 0, ${0.0})`,
                  }}
                />
              )}
            >
              <div
                style={{
                  height: 400,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              ></div>
            </Parallax>
          </div>

          <div
            style={{
              color: "#fff",
              textAlign: "center",
              backgroundColor: "#191919",
              margin: 0,
              padding: 0,
              gap: 0,
              // minHeight: 400,
            }}
            className="right"
          >
            <div style={{ padding: 20 }}>
              <h3 style={{ color: "#fff", paddingTop: 30 }}>Market Test</h3>
              <h4 style={{ color: "#fff" }}>{t("shop")}</h4>
              <p>{t("view")}</p>
              <NoSSR>
                <ScrollToTop />
                <div className="buttons" style={{ paddingBottom: 50 }}>
                  <Link to="/market" className="btn btn-light btn-register">
                    {t("more")}
                  </Link>
                </div>
              </NoSSR>
            </div>
          </div>
        </div>

        <div style={{ gap: 0, marginTop: 100 }} className="container flex-row">
          <div
            style={{
              margin: 0,
              padding: 0,
              gap: 0,
            }}
            className="right"
          >
            <Parallax
              blur={0}
              bgImage={feedback}
              bgImageAlt=" "
              strength={650}
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "#fff",
              }}
              renderLayer={(percentage) => (
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    background: `rgba(0, 0, 0, ${0.0})`,
                  }}
                />
              )}
            >
              <div
                style={{
                  height: 400,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              ></div>
            </Parallax>
          </div>

          <div
            style={{
              color: "#fff",
              textAlign: "center",
              backgroundColor: "#191919",
              margin: 0,
              padding: 0,
              gap: 0,
              // minHeight: 400,
            }}
            className="left"
          >
            <section className="contact-us" id="kontakt">
              <div
                className="container"
                style={{ width: windowDimensions.width / 2, maxWidth: 450 }}
              >
                <h2
                  style={{
                    padding: 0,
                    margin: 20,
                    marginTop: 0,
                    color: "#a4ffee",
                  }}
                >
                  {t("contact")}
                </h2>
                <p>
                  Innovation2Go© <br />
                  Windhofstraße 49 <br />
                  A - 8102 Semriach <br />
                  office@innovation2go.at <br />
                  +43 664 1838845 <br />
                </p>
                <form className="contact-form" ref={form} onSubmit={sendEmail}>
                  <input
                    type="text"
                    className="full-name"
                    placeholder="Name"
                    name="from_name"
                  />
                  <input
                    type="text"
                    className="address"
                    placeholder={t("address")}
                    name="from_adresse"
                  />
                  <input
                    type="email"
                    className="email-address"
                    placeholder={"E-Mail-" + t("address")}
                    name="from_mail"
                  />
                  <input
                    type="tel"
                    className="subject"
                    placeholder={t("number")}
                    name="from_tel"
                  />
                  <input
                    type="text"
                    className="subject"
                    placeholder={t("subject")}
                    name="from_betreff"
                  />
                  <textarea
                    name="from_message"
                    className="message"
                    placeholder={t("input")}
                  ></textarea>
                  <button className="btn btn-light btn-register" type="submit">
                    {t("send")}
                  </button>
                </form>
              </div>
            </section>
          </div>
        </div>
      </section>
      <div
        style={{
          // backgroundColor: "#000",
          width: "100%",
          // paddingTop: 70,
          // marginTop: 50,
          // paddingLeft: 20,
          // paddingRight: 20,
          // paddingBottom: 0,
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#000",
            padding: 20,
            margin: 50,
            maxWidth: 1250,
            paddingTop: 70,
            paddingBottom: 70,
            width: "100%",
          }}
          id="öffnungszeiten"
        >
          <p style={{ fontSize: 40, color: "#a4ffee" }}>{t("hours")}</p>

          <div
            style={{
              color: "#fff",
              marginTop: 20,
              paddingBottom: 30,
              fontSize: 15,
              width: "100%",
              maxWidth: 1250,
              lineHeight: 1.7,
              fontWeight: "normal",
            }}
          >
            {t("visit")}
            <br />
            <br />
            <div
              style={{
                color: "#a4ffee",
                fontSize: 15,
              }}
            >
              {'"' + t("gone") + '"' + " [Innovation2Go]"}
            </div>
            <br />
            {t("wait")}
          </div>

          <div className="buttons" style={{}}>
            <a
              style={{ margin: 5 }}
              href="tel:+43 3127 88304"
              className="btn btn-light btn-register"
            >
              {t("phone")}
            </a>
            <a
              style={{ margin: 5 }}
              href="mailto:office@innovation2go.at"
              className="btn btn-login-forgot buttonSpecial"
            >
              {t("mail")}
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Home;
