import React, { useEffect } from "react";
import "../style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Link } from "react-router-dom";
import NoSSR from "react-no-ssr";
import ScrollToTop from "./ScrollToTop";
import { t } from "i18next";

function Header() {
  // useEffect(() => {
  //   const login4 = document.querySelector(".btn-login4");
  //   const loginModal = document.querySelector(".login-modal-wrap");

  //   login4.addEventListener("click", () => {
  //     loginModal.classList.toggle("display-none");
  //   });
  // }, []);

  return (
    <footer>
      <div className="container">
        <ul className="flex-row">
          <li>
            <a href="/#hero">Start</a>
          </li>
          <li>
            <a href="/#portfolio">Portfolio</a>
          </li>
          <li>
            <a href="/#service">Services</a>
          </li>
          {/* <li>
            <a href="#forum">Forum</a>
          </li> */}
          <li>
            <a href="/#kontakt"> {t("contact")}</a>
          </li>
          <li>
            <a href="/#öffnungszeiten"> {t("open")}</a>
          </li>
          <li>
            <Link to="/shop">Marketplace</Link>
          </li>
        </ul>
        <NoSSR>
          <ScrollToTop />
          <div className="buttons" style={{ paddingBottom: 50 }}>
            <Link to="/impressum" className="btn btn-light btn-register">
              {t("imprint")}
            </Link>
            <Link
              style={{ marginLeft: 20 }}
              to="/datenschutz"
              className="btn btn-light btn-register"
            >
              {t("privacy")}
            </Link>
          </div>
        </NoSSR>

        <p>{"©2020 Innovation2Go™"}</p>
      </div>
    </footer>
  );
}

export default Header;
