import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: localStorage.getItem("language") || "de",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    de: {
      translation: {
        germ: "Deutsch",
        eng: "Englisch",
        sellang: "Deutsch",
        contact: "Kontakt",
        open: "Öffnungszeiten",
        welc: " heißt",
        you: "dich willkommen -",
        glad: "schön, dass Du da bist.",
        tailor: "Maßgeschneiderte Beratung - Offen & Pragmatisch",
        aboutus: "Über uns",
        solutions: "Lösungen für deinen Erfolg",
        imprint: "Impressum",
        privacy: "Datenschutz",
        portfolio: "Unser Portfolio",
        success: "Wir verhelfen Unternehmen zum Erfolg",
        consulting: "Start Up Beratung",
        management: "Managementberatung / -consulting",
        pioneering: "Wegweisend",
        new: "Neue Wege gehen",
        numbers: "Zahlen, Daten, Fakten",
        test: "Market Test | Business Pitch",
        future: "Vergangenheit - Gegenwart - Zukunft",
        reality: "Die Realitiät",
        contactus:
          "Kontaktiere uns, um zu besprechen, wie unser talentiertes Team dir helfen kann, relevante Ergebnisse zu erzielen.",
        hours: "Geschäftszeiten",
        more: "Mehr",
        goals: "Erreiche deine Ziele",
        transp: "Transparents schaffen",
        space: "Business & Produktinnovationen Game Changers Space",
        products: "Besondere Produkte mit besondere Möglichkeiten",
        shop: "Dein Produkt in unserem Shop",
        send: "Absenden",
        visit:
          "Wir freuen uns auf deinen Besuch, egal ob vor Ort oder virtuell.",
        gone: "Gute Gelegenheiten warten nicht, wenn du nicht da bist sind sie vorbei.",
        wait: "Daher lassen wir dich auch nicht warten. Wir freuen uns auf deinen Kontakt.",
        phone: "Telefonkontakt",
        mail: "Emailkontakt",
        fast: "Einfach, schnell und unbürokratisch. Wir wissen genau wie wichtig es, vor allem in der frühen Startphase ist, schnell Lösungen zu finden und diese voranzutreiben. Aus diesem Grund arbeiten wir vor allem mit Start-Ups sehr agil zusammen und sind immer bemüht, schnell und pragmatisch Lösungen anzubieten. Daher sind nahezu alle Lösungen individuell und zielgerichtet für dein Unternehmen.",
        agility: "Agilität",
        sus: "Nachhaltigkeit",
        support:
          "Wir unterstützen dich in sämtlichen Fragestellungen quer durch sämtliche Organisationthemen in Form von Beratung, operativer Unterstützung bis hin zu Trainings, weiters von der Konzeptionierung bis hin zur Umsetzung oder Optimierung. In sämtlichen Fragestellung legen wir einen besonderen Wert auf eine gesamtheitliche Betrachtung, jedoch immer mit dem notwendigen Fokus auf deine Problem- stellung. Hierzu sind zweit Themen für uns von Bedeutung:",
        know: "Wissen ist nicht alles, aber ohne Wissen ist alles Nichts. So ist es eben auch mit Informationen und Daten. Die richtigen Informationen z.B.: über Märkte, Kunden, Wettbewerber oder Technologien sind für eine zielgerichtete Unternehmensteuerung unabdingbar.",
        kpi: "Gerne erarbeiten wir mittels state of the art Verfahren entsprechende Informationen oder KPI´s. ",
        yourprod:
          "Dein Produkt ist bereit für den ersten Marktauftritt, dann nutze die Möglichkeit zur Präsentation und zum Verkauf auf unser Online Verkaufsplattform.",
        here: "Hier hast Du die Chance deine ganz besondere Innovation einem ausgewählten Netzwerk an Personen bzw. Unternehmen, mit starkem Interesse an neuen Möglichkeiten, vorzustellen und womöglich einen Pitch zu landen, der abhebt.",
        inno: "Unser Team aus erfahrenen Experten ist in der Lage, die Bedürfnisse von Kunden aller Art und Größe zu erfüllen – von Start - Ups bis hin zu großen Unternehmen. Wir sorgen für nachhaltige Veränderungen und messbares Wachstum. Kontaktiere uns und erfahre, wie",
        canhelp:
          "dir, deinen Unternehmungen und deinem Unternehmen auf dem Weg zum Erfolg unterstützen kann.",
        knowhow:
          "Du möchtest dein Unternehmen weiterentwickeln, bist aber nicht sicher, was Du tun sollst? Möchtest deine innovative Idee auf den Markt bringen? Möchtest eine externe Meinung? Verfügst nicht über das notwendige Know How?",
        prompt:
          "Lass dich von uns unterstützen. Jedes Unternehmen kann mit kleinen, schrittweisen Veränderungen vorankommen. Um aber in der sich heute schnell entwickelnden Welt und den Märkten für die Zukunft gerüstet zu sein, muss man Chancen mit Mut ergreifen und durchdachte Entscheidungen zeitnahe treffen.",
        wind: "Wir unterstützen dich dabei und sorgen für den notwendigen Aufwind.",
        invest:
          "Wir unterstützen oder erarbeiten sämtliche unternehmensrelevanten KPI´s. Von der einfachen Kostenanalyse bis hin zu Performanceanalysen in den unterschiedlichen Bereiche und umfangreichen Wirtschaftlichkeitsbetrachtungen für deine Investitionen. Gerne erarbeiten und implementieren wir auch ein entsprechendes Konzept für eine fortlaufende Bewertung.",
        create:
          "Erstellung von Businessplänen, Marktanalysen, Wettbewerbsanalysen, Wirtschaftlichkeitsanalysen, Technologieanalysen,...",
        creation:
          "Du hast einfach nicht die Zeit oder Lust dich mit der Erstellung von Businessplänen, Marktanalysen, Wirtschaftlichkeitsanalysen,... zu beschäftigen?",
        fun: "Kein Problem, mach das was dir Spaß macht, den Rest übernehmen wir für dich.",
        strong:
          "Wir bringen zusammen, was zusammen gehört. Menschen mit einzigartigen Innovationen und Menschen mit einzigartigen Erfahrungen und Möglichkeiten. Dieser Bereich ist für all jene mit derselben Leidenschaft, Ideen gemeinsam umzusetzen und stark zu machen. Wenn Du eine interessante Idee oder ein besonderes Produkt hast, bewirb dich. Wenn Du eine solche wirtschaftlich begleiten möchtest, nimm mit uns Kontakt auf.",
        personal:
          "Der Zugang in diesen Bereich ist sehr erlesen und daher auch nur nach persönlicher Freigabe erreichbar.",
        free: "Bei Interesse kannst Du uns gerne kontaktieren.",
        view: "Wir unterstützen neue und innovative Produkte dabei im Sichtfeld der Zielgruppe zu erscheinen. Wir helfen deinem Produkt bekannt zu werden und platzieren es in unseren Shop.",
        address: "Adresse",
        number: "Telefonnummer",
        subject: "Betreff",
        input: "Geben Sie hier Ihre Nachricht ein...",
        ourteam:
          "Unser Team unterstützt dich bei der Entwicklung von Strategien, die dir nicht nur dabei helfen sollen weiterhin zu bestehen, sondern in der Zukunft noch erfolgreicher zu sein. Gerne kannst Du dich bei uns melden, um ein erstes Beratungsgespräch zu vereinbaren.",
        wefrom: "Wir von",
        step: "verstehen, dass Veränderungen nicht immer einfach sind.  Seit mehr als 10 Jahren helfen wir daher Unternehmen aller Größenordnungen und Branchen, sich entsprechend aufzustellen und auszurichten um in den agilen Märkten wettbewerbsfähig zu bleiben. Dein Geschäftserfolg steht bei uns immer an erster Stelle.",
        startnow:
          "Wir unterstützen neue und innovative Produkte dabei im Sichtfeld der Zielgruppe zu erscheinen. Wir helfen deinem Produkt bekannt zu machen und platzieren sie in unserem Shop. Worauf warten? Jetzt Starten!",
        small: "Wir sind ein kleines Unternehmen dessen Berufung im ",
        strat: "strategisches Business Development",
        andthe: " und in der ",
        dev: "Beratung und Entwicklung von Start-Ups",
        and: " und ",
        innov: "innovativen Produkten. ",
        reach:
          "Wir unterstützen und begleiten euch bei der Umsetzung und Erreichung eurer ",
        inivgoals:
          "individuellen Ziele. Wir arbeiten eng mit deinem Unternehmen zusammen, um schnell maßgeschneiderte und ",
        shared:
          "praxisorientierte Lösungen zu finden. Wir freuen uns auf eine spannende Zeit und gemeinsamen Erfolgen.",
      },
    },
    en: {
      translation: {
        germ: "German",
        eng: "English",
        sellang: "English",
        contact: "Contact",
        open: "Opening hours",
        welc: " ",
        you: "welcomes you -",
        glad: "glad you are here!",
        tailor: "Tailor-made advice - open & pragmatic",
        aboutus: "About us",
        solutions: "Solutions for your success",
        imprint: "Imprint",
        privacy: "Privacy policy",
        portfolio: "Our portfolio",
        success: "We help companies to success",
        consulting: "Start Up Consulting",
        management: "Management advice / consulting",
        pioneering: "Pioneering",
        new: "Breaking new ground",
        numbers: "Figures, data, facts",
        test: "Market Tests | Business Pitches",
        future: "Past - Present - Future",
        reality: "The reality",
        contactus:
          "Contact us to discuss how our talented team can help you achieve relevant results.",
        hours: "Business hours",
        more: "More",
        goals: "Achieve your goals",
        transp: "Create transparency",
        space: "Business & product innovations game changers space",
        products: "Unique products with unique possibilities",
        shop: "Your product in our store",
        send: "Send",
        visit:
          "We are look forward to your visit, whether in person or virtually.",
        gone: "Good opportunities don't wait, if you're not there they're gone.",
        wait: "That's why we won't keep you waiting. We look forward to hearing from you.",
        phone: "Mobile contact",
        mail: "Email contact",
        fast: "Simple, fast and unbureaucratic. We know exactly how important it is, especially in the early start-up phase, to find solutions quickly and drive them forward. For this reason, we work with start-ups in a very agile way and always strive to offer quick and pragmatic solutions. As a result, almost all solutions are customized and targeted for your company.",
        agility: "Agility",
        sus: "Sustainability",
        support:
          "We support you in all issues across all organizational topics in the form of consulting, operational support and training, from conceptual design to implementation or optimization. We attach particular importance to a holistic approach to all issues, but always with the necessary focus on your problem. Two topics are important to us in this regard:",
        know: "Knowledge is not everything, but without knowledge everything is nothing. It is the same with information and data. The right information, e.g. about markets, customers, competitors or technologies, is essential for targeted corporate management.",
        kpi: "We are happy to develop corresponding information or KPIs using state-of-the-art procedures. ",
        yourprod:
          "Your product is ready for its first market appearance, then take the opportunity to present and sell it on our online sales platform.",
        here: "Here you have the chance to present your very special innovation to a selected network of people or companies with a strong interest in new opportunities and possibly land a pitch that takes off.",
        inno: "Our team of experienced experts is able to meet the needs of clients of all types and sizes - from start-ups to large companies. We deliver sustainable change and measurable growth. Contact us and find out how",
        canhelp:
          "can help you, your business and your company on the road to success.",
        knowhow:
          "Do you want to develop your company but aren't sure what to do? Want to bring your innovative idea to market? Would you like an external opinion? Don't have the necessary know-how?",
        prompt:
          "Let us support you. Every company can make progress with small, gradual changes. But to be prepared for the future in today's rapidly developing world and markets, you need to seize opportunities with courage and make well thought-out decisions promptly.",
        wind: "We support you and provide the necessary boost.",
        invest:
          "We support or develop all company-relevant KPIs. From simple cost analyses to performance analyses in the various areas and comprehensive profitability analyses for your investments. We are also happy to develop and implement an appropriate concept for ongoing evaluation.",
        create:
          "Preparation of business plans, market analyses, competitive analyses, profitability analyses, technology analyses,...",
        creation:
          "You simply don't have the time or desire to deal with the creation of business plans, market analyses, profitability analyses?",
        fun: "No problem, do what you enjoy and we'll do the rest for you.",
        strong:
          "We bring together what belongs together. People with unique innovations and people with unique experiences and opportunities. This area is for all those with the same passion for implementing ideas together and making them strong. If you have an interesting idea or a special product, apply. If you would like to accompany such an idea economically, get in touch with us.",
        personal:
          "Access to this area is very select and therefore only accessible after personal approval.",
        free: "If you are interested, please feel free to contact us.",
        view: "We support new and innovative products to appear in the target group's field of vision. We help your product to become known and place it in our store.",
        address: "Address",
        number: "Phone number",
        subject: "Subject",
        input: "Enter your message here...",
        ourteam:
          "Our team will support you in developing strategies that will not only help you to survive, but also to be even more successful in the future. You are welcome to contact us to arrange an initial consultation.",
        wefrom: "We from",
        step: "understand that change is not always easy.  For more than 10 years, we have therefore been helping companies of all sizes and from all industries to position and align themselves accordingly in order to remain competitive in agile markets. Your business success is always our top priority.",
        startnow:
          "We support new and innovative products to appear in the target group's field of vision. We help to make your product known and place it in our store. What are you waiting for? Start now!",
        small: "We are a small company whose vocation is ",
        strat: "strategic business development",
        andthe: " and in the ",
        dev: "consulting and development of start-ups",
        and: " and ",
        innov: "innovative products. ",
        reach:
          "We support and accompany you in the implementation and achievement of your ",
        inivgoals:
          "individual goals. We work closely with your company to quickly develop customized and ",
        shared:
          "to find practical solutions. We look forward to an exciting time and shared successes.",
      },
    },
  },
});

export default i18n;
