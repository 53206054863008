
import React, { useState } from "react";
import Typical from "react-typical";
  
export default class TypicalText extends React.PureComponent{
   render(){
      return (
        <div
          style={{ overflow: "hidden" }}
          // className={`Mod ${animation}`}
          id="hide"
        >
          <div style={{ height: 5 }}></div>
          <Typical
            steps={[
              "# L E T Y O U R B U S I N E S S I D E A S F L Y",
              1000,
              // "# L E T Y O U R ",
              // 10,
              // "# L E T Y O U R I N N O V A T I O N F L Y",
              // 1000,
              "#LETYOURBUSINESSIDEASFLY",
              1000,
            ]}
            loop={1}
            wrapper="p"
             
          />
          <div style={{ height: 15 }}></div>
          <div style={{ fontSize: 15, fontWeight: "normal", color: "#fff" }}>
            {
              "Business Creator and Elevator - Developing Ideas and Businesses - Consulting and Investment"
            }
          </div>
        </div>
      );
   }
}