import React, { useState } from "react";
import "../style.scss";
import logo from "../img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useEffect, useRef } from "react";
import { getDatabase, ref, set } from "firebase/database";
import { db } from "../firebase/firebase";
import { addDoc, collection } from "firebase/firestore";
import Typical from "react-typical";
import TypicalText from "./typicalText";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Header() {
  const { t, i18n } = useTranslation();
  // const languages = [ { code: "de", name: "German"}, {code: "en", name: "English"}];

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [animation, setAnimation] = useState("open");
  const [display, setDisplay] = useState(true);

  const hide = async (ms) => {
    setAnimation("close");

    await new Promise((r) => setTimeout(r, ms));

    setDisplay(false);
  };

  const open = async (ms) => {
    setAnimation("open");

    await new Promise((r) => setTimeout(r, ms));

    setDisplay(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  const listenToScroll = () => {
    let heightToHideFrom = 200;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      hide(1000);
    } else {
      open(1000);
    }
  };

  const [isVisible, setIsVisible] = useState(true);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const TypingAnimation = React.memo(
    () => {
      return (
        <div
          style={{ overflow: "hidden" }}
          // className={`Mod ${animation}`}
          // id="hide"
        >
          <div style={{ height: 5 }}></div>

          <div
            style={{
              fontSize:
                windowDimensions.width * 0.035 > 30
                  ? 30
                  : windowDimensions.width * 0.035,
            }}
          >
            {"# L E T Y O U R B U S I N E S S I D E A S F L Y"}
            {/* <Typical
            steps={[
              // "#LETYOURBUSINESSIDEASFLY",
              // 1000,
              "# L E T Y O U R B U S I N E S S I D E A S F L Y",
              1000,
            ]}
            loop={1}
            wrapper="p"
          /> */}
          </div>
          <div style={{ height: 15 }}></div>
          <div style={{ fontSize: 15, fontWeight: "normal", color: "#fff" }}>
            {
              "Business Creator and Elevator - Developing Ideas and Businesses - Consulting and Investment"
            }
          </div>
        </div>
      );
    },
    (props, prevProp) => true
  ); // this line prevent re rendering

  useEffect(() => {
    console.log("loaded");

    const openIcon = document.querySelector(".fa-bars");
    const closeIcon = document.querySelector(".close-nav");
    const mobileBG = document.querySelector(".mobile-bg");
    const mobileNav = document.querySelector(".mobile-nav");
    const navItem = document.querySelector(".nav-item");
    const navItem2 = document.querySelector(".nav-item2");
    const navItem3 = document.querySelector(".nav-item3");
    const navItem4 = document.querySelector(".nav-item4");

    openIcon.addEventListener("click", () => {
      mobileBG.classList.toggle("display-none");
      mobileNav.classList.remove("slide-out");
      mobileNav.classList.toggle("slide-in");
      openIcon.classList.toggle("display-none");
    });

    closeIcon.addEventListener("click", () => {
      setTimeout(() => {
        mobileBG.classList.toggle("display-none");
        openIcon.classList.toggle("display-none");
      }, 500);
      mobileNav.classList.toggle("slide-in");
      mobileNav.classList.toggle("slide-out");
    });

    navItem.addEventListener("click", () => {
      mobileBG.classList.toggle("display-none");
      mobileNav.classList.remove("slide-out");
      mobileNav.classList.toggle("slide-in");
      openIcon.classList.toggle("display-none");
    });

    navItem2.addEventListener("click", () => {
      mobileBG.classList.toggle("display-none");
      mobileNav.classList.remove("slide-out");
      mobileNav.classList.toggle("slide-in");
      openIcon.classList.toggle("display-none");
    });

    navItem3.addEventListener("click", () => {
      mobileBG.classList.toggle("display-none");
      mobileNav.classList.remove("slide-out");
      mobileNav.classList.toggle("slide-in");
      openIcon.classList.toggle("display-none");
    });

    navItem4.addEventListener("click", () => {
      mobileBG.classList.toggle("display-none");
      mobileNav.classList.remove("slide-out");
      mobileNav.classList.toggle("slide-in");
      openIcon.classList.toggle("display-none");
    });

    const contactForm = document.querySelector(".contact-form");
    const fullName = document.querySelector(".full-name");
    const emailAddress = document.querySelector(".email-address");
    const subject = document.querySelector(".subject");
    const message = document.querySelector(".message");
    const submit = document.querySelector(".submit-form");
    const modal = document.querySelector(".modal-wrap");
    const close = document.querySelector(".modal-close");

    close.addEventListener("click", () => {
      modal.classList.toggle("display-none");
    });

    const login = document.querySelector(".btn-login");
    const login2 = document.querySelector(".btn-login2");
    const loginModal = document.querySelector(".login-modal-wrap");

    login.addEventListener("click", () => {
      loginModal.classList.toggle("display-none");
    });

    login2.addEventListener("click", () => {
      loginModal.classList.toggle("display-none");
    });
  }, []);

  return (
    <header style={{ backgroundColor: "#191919" }}>
      <div className="mobile-bg display-none">
        <ul className="mobile-nav">
          <li>
            <a href="#hero" className="nav-item">
              Start
            </a>
          </li>
          <li>
            <a href="#portfolio" className="nav-item2 btn-login">
              Portfolio
            </a>
          </li>
          <li>
            <a href="#service" className="nav-item3">
              Services
            </a>
          </li>
          {/* <li>
            <a href="#forum" className="nav-item4">
              Forum
            </a>
          </li> */}
          <li>
            <a href="#kontakt" className="nav-item4">
              {t("contact")}
            </a>
          </li>
          <li>
            <a href="#öffnungszeiten"> {t("open")}</a>
          </li>
          <li>
            <Link to="/shop">Marketplace</Link>
          </li>
          <li>
            <li>
              <ul>
                <li>
                  <a
                    style={{
                      width: 25,
                      height: 25,
                      // marginLeft: 10,
                      backgroundSize: "contain",
                      display: "inline-block",
                      backgroundRepeat: "no-repeat",
                      backgroundImage: `url("https://www.countryflags.com/wp-content/uploads/germany-flag-png-large.png")`,
                    }}
                  >
                    <a
                      onClick={() => {
                        i18n.changeLanguage("de");
                        localStorage.setItem("language", "de");
                      }}
                      style={{ marginLeft: 35 }}
                      href=""
                      className="de"
                    >
                      {t("germ")}
                    </a>
                  </a>
                </li>
                <li>
                  <a
                    style={{
                      width: 25,
                      height: 25,
                      // marginLeft: 10,
                      backgroundSize: "contain",
                      display: "inline-block",
                      backgroundRepeat: "no-repeat",
                      backgroundImage: `url("https://www.countryflags.com/wp-content/uploads/united-states-of-america-flag-png-large.png")`,
                    }}
                  >
                    <a
                      style={{ marginLeft: 35 }}
                      onClick={() => {
                        i18n.changeLanguage("en");
                        localStorage.setItem("language", "en");
                      }}
                      href=""
                      className="en"
                    >
                      {t("eng")}
                    </a>
                  </a>
                </li>
              </ul>
            </li>
          </li>
        </ul>
        <div
          style={{
            backgroundColor: "transparent",
            width: "100%",
            height: "100%",
          }}
          className="close-nav"
        ></div>
      </div>

      <nav
        style={{ backgroundColor: "#191919", maxWidth: "none" }}
        className="container"
      >
        <div style={{ paddingLeft: 15 }}>
          <a
            style={{
              color: "#a4ffee",
              fontSize:
                windowDimensions.width * 0.05 < 28
                  ? windowDimensions.width * 0.05
                  : 28,
              fontWeight: "bold",
            }}
            href=""
          >
            {"I 2 G - I N N O V A T I O N 2 G O"}
            <sup style={{ fontSize: 17, paddingLeft: 2 }}>©</sup>
            {display ? <TypingAnimation></TypingAnimation> : null}
          </a>
        </div>

        <i
          style={{
            paddingRight: "20px",
            color: "#a4ffee",
            paddingLeft: "20px",
          }}
          className="fa-bars"
        >
          <FontAwesomeIcon
            style={{ color: "#a4ffee", cursor: "pointer" }}
            icon={icon({ name: "bars", style: "solid" })}
          />
        </i>
      </nav>
      <div
        style={{
          marginTop: 15,
          width: windowDimensions.width - 30,
          maxWidth: 10000,
          marginLeft:
            (windowDimensions.width - 10000) / 2 > 0
              ? (windowDimensions.width - 10000) / 2
              : 15,
          height: 1,
          backgroundColor: "#fff",
        }}
      ></div>

      <nav
        style={{ backgroundColor: "#191919", maxWidth: "none" }}
        className="container"
      >
        <FontAwesomeIcon
          style={{
            color: "#a4ffee",
            cursor: "pointer",
            paddingLeft: 15,
            fontSize:
              windowDimensions.width * 0.04 < 20
                ? windowDimensions.width * 0.04
                : 20,
          }}
          icon={icon({ name: "phone", style: "solid" })}
        />
        <a
          href="tel:+43 664 1838845"
          style={{
            color: "#a4ffee",
            paddingLeft: 10,
            fontWeight: "normal",
            fontSize:
              windowDimensions.width * 0.03 < 16
                ? windowDimensions.width * 0.03
                : 16,
          }}
        >
          +43 664 1838845
        </a>
        <FontAwesomeIcon
          style={{
            color: "#a4ffee",
            cursor: "pointer",
            paddingLeft: 15,
            fontSize:
              windowDimensions.width * 0.04 < 20
                ? windowDimensions.width * 0.04
                : 20,
          }}
          icon={icon({ name: "envelope", style: "solid" })}
        />
        <a
          href="mailto:office@innovation2go.at"
          style={{
            color: "#a4ffee",
            paddingLeft: 10,
            fontWeight: "normal",
            fontSize:
              windowDimensions.width * 0.03 < 16
                ? windowDimensions.width * 0.03
                : 16,
          }}
        >
          office@innovation2go.at
        </a>
        <ul>
          <li>
            <a href="#">Start</a>
          </li>
          <li>
            <a href="#portfolio" className="btn-login2">
              Portfolio
            </a>
          </li>
          <li>
            <a href="#service">Services</a>
          </li>
          {/* <li>
            <a href="#forum">Forum</a>
          </li> */}
          <li>
            <a href="#kontakt"> {t("contact")}</a>
          </li>
          <li>
            <a href="#öffnungszeiten">{t("open")}</a>
          </li>
          <li>
            <Link to="/shop">Marketplace</Link>
          </li>
          <li>
            <div
              style={{ marginRight: 30, marginLeft: 15, flexDirection: "row" }}
              className="lang-menu"
            >
              <a
                style={{
                  width: 17,
                  height: 17,
                  marginRight: 55,
                  backgroundSize: "contain",
                  display: "inline-block",
                  backgroundRepeat: "no-repeat",
                  backgroundImage:
                    t("sellang") == "Deutsch"
                      ? `url("https://www.countryflags.com/wp-content/uploads/germany-flag-png-large.png")`
                      : `url("https://www.countryflags.com/wp-content/uploads/united-states-of-america-flag-png-large.png")`,
                }}
              >
                <div style={{ marginLeft: 25 }} className="selected-lang">
                  {t("sellang")}
                </div>
              </a>
              <ul>
                <li>
                  <a
                    onClick={() => {
                      i18n.changeLanguage("de");
                      localStorage.setItem("language", "de");
                    }}
                    style={{ marginBottom: 5 }}
                    href=""
                    className="de"
                  >
                    {t("germ")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      i18n.changeLanguage("en");
                      localStorage.setItem("language", "en");
                    }}
                    href=""
                    className="en"
                  >
                    {t("eng")}
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    </header>
  );
}
