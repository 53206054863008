import React, { useState } from "react";
import "../style.scss";
import logo from "../img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useEffect, useRef } from "react";
import { getDatabase, ref, set } from "firebase/database";
import { db } from "../firebase/firebase";
import { addDoc, collection } from "firebase/firestore";
import Typical from "react-typical";
import { Link } from "react-router-dom";
import { t } from "i18next";

export default function Header() {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const openIcon = document.querySelector(".fa-bars2");
    const closeIcon = document.querySelector(".close-nav");
    const mobileBG = document.querySelector(".mobile-bg");
    const mobileNav = document.querySelector(".mobile-nav");
    const navItem = document.querySelector(".nav-item");
    const navItem2 = document.querySelector(".nav-item2");
    const navItem3 = document.querySelector(".nav-item3");
    const navItem4 = document.querySelector(".nav-item4");

    openIcon.addEventListener("click", () => {
      mobileBG.classList.toggle("display-none");
      mobileNav.classList.remove("slide-out");
      mobileNav.classList.toggle("slide-in");
      openIcon.classList.toggle("display-none");
    });

    closeIcon.addEventListener("click", () => {
      setTimeout(() => {
        mobileBG.classList.toggle("display-none");
        openIcon.classList.toggle("display-none");
      }, 500);
      mobileNav.classList.toggle("slide-in");
      mobileNav.classList.toggle("slide-out");
    });

    navItem.addEventListener("click", () => {
      mobileBG.classList.toggle("display-none");
      mobileNav.classList.remove("slide-out");
      mobileNav.classList.toggle("slide-in");
      openIcon.classList.toggle("display-none");
    });

    navItem2.addEventListener("click", () => {
      mobileBG.classList.toggle("display-none");
      mobileNav.classList.remove("slide-out");
      mobileNav.classList.toggle("slide-in");
      openIcon.classList.toggle("display-none");
    });

    navItem3.addEventListener("click", () => {
      mobileBG.classList.toggle("display-none");
      mobileNav.classList.remove("slide-out");
      mobileNav.classList.toggle("slide-in");
      openIcon.classList.toggle("display-none");
    });

    navItem4.addEventListener("click", () => {
      mobileBG.classList.toggle("display-none");
      mobileNav.classList.remove("slide-out");
      mobileNav.classList.toggle("slide-in");
      openIcon.classList.toggle("display-none");
    });

    const contactForm = document.querySelector(".contact-form");
    const fullName = document.querySelector(".full-name");
    const emailAddress = document.querySelector(".email-address");
    const subject = document.querySelector(".subject");
    const message = document.querySelector(".message");
    const submit = document.querySelector(".submit-form");
    const modal = document.querySelector(".modal-wrap");
    //const close = document.querySelector(".modal-close");

    // submit.addEventListener("click", (e) => {
    //   e.preventDefault();

    //   var errortext = "";

    //   if (fullName.value == "") {
    //     errortext += "\nName";
    //   }

    //   if (emailAddress.value == "") {
    //     errortext += "\nE-Mail";
    //   }

    //   if (subject.value == "") {
    //     errortext += "\nBetreff";
    //   }

    //   if (message.value == "") {
    //     errortext += "\nNachricht";
    //   }

    //   if (errortext != "") {
    //     alert("Bitte folgendes angeben: " + errortext);
    //   }

    //   if (errortext == "") {
    //     try {
    //       const docRef = addDoc(collection(db, "Support-Messages"), {
    //         fullName: fullName.value,
    //         email: emailAddress.value,
    //         subject: subject.value,
    //         message: message.value,
    //       });

    //       contactForm.reset();
    //       modal.classList.toggle("display-none");
    //     } catch (e) {
    //       console.error("Error adding document: ", e);
    //     }
    //   }
    // });

    // close.addEventListener("click", () => {
    //   modal.classList.toggle("display-none");
    // });

    const login = document.querySelector(".btn-login");
    const login2 = document.querySelector(".btn-login2");
    const loginModal = document.querySelector(".login-modal-wrap");

    login.addEventListener("click", () => {
      loginModal.classList.toggle("display-none");
    });

    login2.addEventListener("click", () => {
      loginModal.classList.toggle("display-none");
    });
  }, []);

  const TypingAnimation = React.memo(
    () => {
      return (
        <div
          style={{ overflow: "hidden" }}
          // className={`Mod ${animation}`}
          // id="hide"
        >
          <div style={{ height: 5 }}></div>

          <div
            style={{
              fontSize:
                windowDimensions.width * 0.035 > 30
                  ? 30
                  : windowDimensions.width * 0.035,
            }}
          >
            {"# L E T Y O U R B U S I N E S S I D E A S F L Y"}
          </div>
          <div style={{ height: 15 }}></div>
          <div style={{ fontSize: 15, fontWeight: "normal", color: "#fff" }}>
            {
              "Business Creator and Elevator - Developing Ideas and Businesses - Consulting and Investment"
            }
          </div>
        </div>
      );
    },
    (props, prevProp) => true
  );

  return (
    <header style={{ backgroundColor: "#191919" }}>
      <div className="mobile-bg display-none">
        <ul className="mobile-nav">
          <li>
            <a href="/#hero" className="nav-item">
              Start
            </a>
          </li>
          <li>
            <a href="/#portfolio" className="nav-item2 btn-login">
              Portfolio
            </a>
          </li>
          <li>
            <a href="/#service" className="nav-item3">
              Services
            </a>
          </li>
          {/* <li>
            <a href="#forum" className="nav-item4">
              Forum
            </a>
          </li> */}
          <li>
            <a href="/#kontakt" className="nav-item4">
              {t("contact")}
            </a>
          </li>
          <li>
            <a href="/#öffnungszeiten">{t("open")}</a>
          </li>
          <li>
            <Link to="/shop">Marketplace</Link>
          </li>
        </ul>
        <div
          style={{
            backgroundColor: "transparent",
            width: "100%",
            height: "100%",
          }}
          className="close-nav"
        ></div>
      </div>

      <nav
        style={{ backgroundColor: "#191919", maxWidth: "none" }}
        className="container"
      >
        <div style={{ paddingLeft: 15 }}>
          <a
            style={{
              color: "#a4ffee",
              fontSize:
                windowDimensions.width * 0.05 < 28
                  ? windowDimensions.width * 0.05
                  : 28,
              fontWeight: "bold",
            }}
            href=""
          >
            {"I 2 G - I N N O V A T I O N 2 G O"}
            <sup style={{ fontSize: 17, paddingLeft: 2 }}>©</sup>
            <TypingAnimation></TypingAnimation>
          </a>
        </div>

        <i
          style={{
            paddingRight: "20px",
            color: "#a4ffee",
            paddingLeft: "20px",
          }}
          className="fa-bars2"
        >
          <FontAwesomeIcon
            style={{ color: "#a4ffee", cursor: "pointer" }}
            icon={icon({ name: "bars", style: "solid" })}
          />
        </i>
      </nav>
      <div
        style={{
          marginTop: 15,
          width: windowDimensions.width - 30,
          maxWidth: 10000,
          marginLeft:
            (windowDimensions.width - 10000) / 2 > 0
              ? (windowDimensions.width - 10000) / 2
              : 15,
          height: 1,
          backgroundColor: "#fff",
        }}
      ></div>

      <nav
        style={{ backgroundColor: "#191919", maxWidth: "none" }}
        className="container"
      >
        <FontAwesomeIcon
          style={{
            color: "#a4ffee",
            cursor: "pointer",
            paddingLeft: 15,
            fontSize:
              windowDimensions.width * 0.04 < 20
                ? windowDimensions.width * 0.04
                : 20,
          }}
          icon={icon({ name: "phone", style: "solid" })}
        />
        <a
          href="tel:+43 664 1838845"
          style={{
            color: "#a4ffee",
            paddingLeft: 10,
            fontWeight: "normal",
            fontSize:
              windowDimensions.width * 0.03 < 16
                ? windowDimensions.width * 0.03
                : 16,
          }}
        >
          +43 664 1838845
        </a>
        <FontAwesomeIcon
          style={{
            color: "#a4ffee",
            cursor: "pointer",
            paddingLeft: 15,
            fontSize:
              windowDimensions.width * 0.04 < 20
                ? windowDimensions.width * 0.04
                : 20,
          }}
          icon={icon({ name: "envelope", style: "solid" })}
        />
        <a
          href="mailto:office@innovation2go.at"
          style={{
            color: "#a4ffee",
            paddingLeft: 10,
            fontWeight: "normal",
            fontSize:
              windowDimensions.width * 0.03 < 16
                ? windowDimensions.width * 0.03
                : 16,
          }}
        >
          office@innovation2go.at
        </a>
        <ul>
          <li>
            <a href="/#">Start</a>
          </li>
          <li>
            <a href="/#portfolio" className="btn-login2">
              Portfolio
            </a>
          </li>
          <li>
            <a href="/#service">Services</a>
          </li>
          {/* <li>
            <a href="#forum">Forum</a>
          </li> */}
          <li>
            <a href="/#kontakt"> {t("contact")}</a>
          </li>
          <li>
            <a href="/#öffnungszeiten">{t("open")}</a>
          </li>
          <li>
            <Link to="/shop">Marketplace</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}
