import "../style.scss";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import logo from "../img/logo.png";
import Header from "components/Header";
import Footer_Under from "components/Footer_Under";
import Header_Under from "components/Header_Under";
import { t } from "i18next";

export default function Impressum() {
  useEffect(() => {
    //mobile nav
    const openIcon = document.querySelector(".fa-bars");
    const closeIcon = document.querySelector(".close-nav");
    const mobileBG = document.querySelector(".mobile-bg");
    const mobileNav = document.querySelector(".mobile-nav");
    const navItem = document.querySelector(".nav-item");

    openIcon.addEventListener("click", () => {
      mobileBG.classList.toggle("display-none");
      mobileNav.classList.remove("slide-out");
      mobileNav.classList.toggle("slide-in");
      openIcon.classList.toggle("display-none");
    });

    closeIcon.addEventListener("click", () => {
      setTimeout(() => {
        mobileBG.classList.toggle("display-none");
        openIcon.classList.toggle("display-none");
      }, 500);
      mobileNav.classList.toggle("slide-in");
      mobileNav.classList.toggle("slide-out");
    });

    navItem.addEventListener("click", () => {
      mobileBG.classList.toggle("display-none");
      mobileNav.classList.remove("slide-out");
      mobileNav.classList.toggle("slide-in");
      openIcon.classList.toggle("display-none");
    });
  });

  return (
    <html lang="de">
      <body style={{ backgroundColor: "#191919" }}>
        {/* Mobile Nav */}
        {/* <div className="mobile-bg display-none">
          <ul className="mobile-nav">
            <li>
              <a href="/" className="nav-item">
                Zurück
              </a>
            </li>
          </ul>
          <div
            className="close-nav"
            style={{
              backgroundColor: "transparent",
              width: "100%",
              height: "100%",
            }}
          ></div>
        </div> */}
        {/* Nav */}
        <Header_Under></Header_Under>

        <div style={{ height: 130 }}></div>

        {/* <header>
          <nav className="container">
            <a>
              <img src={logo} alt="" />
            </a>

            <i
              style={{ paddingRight: "20px", color: "#a4ffee" }}
              className="fas fa-bars"
            >
              <FontAwesomeIcon
                style={{ color: "#a4ffee", cursor: "pointer" }}
                icon={icon({ name: "bars", style: "solid" })}
              />
            </i>
            <ul>
              <li>
                <a href="/">Zurück</a>
              </li>
            </ul>
          </nav>
        </header> */}

        <div
          className="container"
          style={{ padding: "100px 50px", paddingBottom: "50px" }}
        >
          <h2
            style={{
              color: "#a4ffee",
              textAlign: "left",
              paddingBottom: "20px",
            }}
          >
            {t("imprint")}
          </h2>
          <p style={{ color: "#fff", whiteSpace: "pre-wrap" }}>
            {`Impressum

Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.

Dipl.-Ing. Arnold Auer, MBA
Windhofstraße 49,
8102 Semriach,
Österreich

Unternehmensgegenstand: Unternehmensberatung einschließlich der Unternehmensorganisation

Tel.: +43 664 1838845
Fax: +43 664 1838845
E-Mail: office@innovation2go.at

Mitglied bei: WKO
Berufsrecht: Gewerbeordnung: www.ris.bka.gv.at

Aufsichtsbehörde/Gewerbebehörde: Bezirkshauptmannschaft Graz Umgebung
Berufsbezeichnung: Unternehmensberater
Verleihungsstaat: Österreich

Quelle: Erstellt mit dem Impressum Generator von AdSimple® Webdesign in Kooperation mit aboutbusiness.at

EU-Streitschlichtung

Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.
Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission unter http://ec.europa.eu/odr?tid=121610086 zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.

Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.

Haftung für Inhalte dieser Webseite

Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden.

Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitten wir Sie uns umgehend zu kontaktieren, Sie finden die Kontaktdaten im Impressum.

Haftung für Links auf dieser Webseite

Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Haftung für verlinkte Websites besteht laut § 17 ECG für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.

Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitten wir Sie uns zu kontaktieren, Sie finden die Kontaktdaten im Impressum.

Urheberrechtshinweis

Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.

Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.

Bildernachweis

Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.

Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:

WIX.com`}
          </p>
        </div>

        <Footer_Under></Footer_Under>

        {/* <footer>
          <div className="container">
            <ul className="flex-row">
              <li>
                <a href="/">Zurück</a>
              </li>
            </ul>
            <ul className="flex-row">
              <li>
                <i className="fa-brands fa-google-play">
                  <FontAwesomeIcon
                    icon={icon({ name: "google-play", style: "brands" })}
                  />
                </i>
              </li>
              <li>
                <i className="fa-brands fa-app-store-ios">
                  <FontAwesomeIcon
                    icon={icon({ name: "app-store-ios", style: "brands" })}
                  />
                </i>
              </li>
            </ul>
            <p>{"© 2022 Innovation2Go"}</p>
          </div>
        </footer> */}
      </body>
    </html>
  );
}
