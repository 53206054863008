import "./style.scss";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import React, { useState, useEffect } from "react";
import { db } from "./firebase/firebase";
import { collection, getDocs } from "firebase/firestore";

function App() {

  return (
    
    <html lang="de">
      <body>
        {/* Contact Moda */}
        <div className="modal-wrap display-none">
          <div className="modal">
            <img
              src={require("./img/contact-thank-you.svg").default}
              alt=""
            />

            <p>Danke für Ihre Anfrage.</p>
            <p>Wir werden Sie benachrichtigen.</p>
            <button className="btn modal-close">Ok</button>
          </div>
        </div>

        <Header></Header>
        <Home></Home>
        <Footer></Footer>
      </body>
    </html>
  );
}

export default App;
