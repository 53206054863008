import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

var firebaseConfig = {
  apiKey: "AIzaSyBHx9jaguxfQT6GW2J2r0Uh9w9SuR8dsxY",
  authDomain: "innovation2go-i2g.firebaseapp.com",
  projectId: "innovation2go-i2g",
  storageBucket: "innovation2go-i2g.appspot.com",
  messagingSenderId: "758287483353",
  appId: "1:758287483353:web:caaa9c5cfe2af3e66459c8",
  measurementId: "G-GZT40FNRYS",
};


const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);


